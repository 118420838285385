* {
  margin: 0;
  padding: 0;

  --text-color: rgb(229, 229, 229);
  --primary-color: rgb(41, 161, 156);
  --primary-color-lighten: rgb(100, 217, 213);
  --primary-color-darken: rgb(34, 114, 112);
  --secondary-color: rgb(161, 247, 190);
  --secondary-color-lighten: rgb(197, 255, 216);
  --secondary-color-darken: rgb(119, 196, 144);
  --surface-0: rgb(28, 29, 30);
  --surface-1: rgb(24, 25, 26);
  --surface-2: rgb(55, 59, 65);
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Light.ttf");
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-Light.ttf");
}

@font-face {
  font-family: "Oswald";
  src: url("./fonts/Oswald-Regular.ttf");
  font-weight: bold;
}

h1,
h2,
h3 {
  font-family: Oswald, Tahoma, sans-serif;
  letter-spacing: 0.05rem;
  text-transform: lowercase;
}

h1 {
  margin-block-start: 1.5rem;
  margin-block-end: 0.75rem;
  font-size: 1.8rem;
}

h2 {
  margin-block-start: 1rem;
  margin-block-end: 0.5rem;
  font-size: 1.5rem;
  color: var(--primary-color);
}
h2::before {
  content: ".";
}

h3 {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
  font-size: 1rem;
  color: var(--primary-color-lighten);
}
h3::before {
  content: "_";
}

html {
  min-height: 100vh;
}

body {
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  margin: 0;
  background-color: var(--surface-0);
  color: var(--text-color);
  font-size: 12pt;
  font-family: Nunito;

  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-stretch: ultra-condensed;
}

#root {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
}

#root:empty {
  align-items: center;
  transform-style: preserve-3d;
  perspective: 50px;
  padding: 0;
  content: "sasdsa"
}

#root:empty::before {
  content: "<welcome />";
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link {
  color: var(--primary-color);
  text-decoration: none;
}

a:visited {
  color: var(--primary-color-darken)
}

a:hover {
  color: var(--primary-color-lighten);
  transition: all 250ms ease-out;
}

a:active {
  text-decoration: underline;
}

a.icon {
  display: inline-flex;
}

.accessibility-only {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; 
} 
